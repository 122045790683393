'use client';

import React, { memo } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { LEAGUES_POPULAR } from '@/constants/core';

import { Card, CardContent } from '@/components/ui/card';
import {
  Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious,
} from '@/components/ui/carousel';

const TopLeagues = memo(() => {
  return (
    <Carousel>
      <div className="flex justify-between gap-3 pb-0">
        <Link href="/deportes" prefetch={false} passHref className="flex items-baseline gap-2">
          <span className="icon icon-trophy" />
          <h2 className="text-base sm:text-sm md:text-lg lg:text-xl font-medium text-sivarbet-text pb-3">
            Ligas Populares
          </h2>
        </Link>
        <div className="flex flex-row-reverse gap-3 pb-2">
          <CarouselNext className="static rounded-lg border-sivarbet-border flex items-center bg-transparent transform-none" />
          <CarouselPrevious className="static rounded-lg border-sivarbet-border flex items-center bg-transparent transform-none" />
        </div>
      </div>
      <CarouselContent>
        {LEAGUES_POPULAR.map((league) => {
          return (
            <CarouselItem
              className="basis-1/2 sm:basis-1/3 md:basis-1/4 lg:basis-1/6 h-[100px] sm:h-[117px]"
              key={league.href}
            >
              <Link href={league.href} prefetch={false}>
                <Card className="bg-sivarbet-secondary-bg h-full border-none hover:opacity-70">
                  <CardContent className="p-4 sm:p-6 h-full flex justify-center items-center">
                    {league.png ? (
                      <Image src={league.png} alt={league.alt} className="w-auto object-contain h-full" />
                    ) : (
                      <span className={`icon ${league.src} text-sivarbet-text text-7xl hover:text-sivarbet-primary-hover`} />
                    )}
                  </CardContent>
                </Card>
              </Link>
            </CarouselItem>
          );
        })}
      </CarouselContent>
    </Carousel>
  );
});

export default memo(TopLeagues);
